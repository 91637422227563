import {defineStore} from 'pinia'

export const useStaticStore = defineStore('static', {
    state: () => ({
        statuses: [],
        userLocations: [],
    }),

    actions: {
        loadStaticData() {
            return $lara.get(useNuxtApp().$apiRoute('spa.statics.index')).then((response) => {
                this.statuses = response?.statuses || []
                this.userLocations = response?.userLocations || []
            })
        }
    }
})
