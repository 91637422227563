export default {
    finance: 'Finanzverwaltung',
    cost_center: 'Kostenstelle',
    data_center: 'Rechenzentrum',
    tech_department: 'Technische Abteilung',
    additional_approvals: 'Zusätzliche Genehmigungen',
    decision_dc_td: 'Entscheidung RZ/TA',

    noneRequired: 'keine erforderlich',
    notDecided: 'Nicht entschieden',
    approve: 'Genehmigen',
    reject: 'Ablehnen',
    correct: 'Korrektur',
    finalReject: 'Endgültig ablehnen',
    requestCorrection: 'Korrektur anfordern',
    requestedCorrection: 'Korrektur angefordert',
    approved: 'genehmigt',
    rejected: 'abgelehnt',
    correction: 'zu korrigieren',
    none: 'keine',
    open: 'offen',
    decisionUnknown: 'Entscheidung unbekannt',

    department: 'Fachbereich',
    user: 'Genehmigender',
    decision: 'Entscheidung',
    explanation: 'Begründung',
    time: 'Zeitpunkt',

    shouldOrderBeRejected: 'Möchten Sie diesen Beschaffungsantrag endgültig ablehnen?',
    shouldOrderBeCorrected: 'Möchten Sie eine Korrektur anfordern?',
    shouldOrderBeApproved: 'Möchten Sie diesen Beschaffungsantrag genehmigen?',
    approvalError: 'Genehmigungsfehler',
    approvalErrorDescription: 'Die Beschaffung konnte nicht genehmigt werden.',

    confirmSelection: 'Auswahl bestätigen',
    decisionExplanation: 'Sie können entscheiden, ob für diesen Beschaffungsantrag zusätzliche Genehmigungen erforderlich sind. Wählen Sie eine der folgenden Optionen um den Genehmigungsprozess in den nächsten Schritt zu bringen.',
    approvalExplanation: 'Dieser Beschaffungsantrag benötigt Ihre Genehmigung. Bitte überprüfen Sie die Details und entscheiden Sie über die Freigabe.'
}