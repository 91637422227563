import {useAbility} from '@casl/vue'
import debounce from 'lodash/debounce'
import {defineStore} from 'pinia'
import {v4 as uuid} from 'uuid'
import {dashboard} from '@/utils/dashboard'

const debouncedSave = debounce((layouts, widgets) => {
    $lara.post(useNuxtApp().$apiRoute('spa.dashboard.store'), {body: {dashboard: {layouts, widgets}}})
}, 500)

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        isSidebarOpen: false,
        wikiSidebarOpen: false,
        isSubheaderOpen: false,
        currentModule: null,
        breakpoint: null,
        dashboardGrid: [], // stores the local and visible state of the dashboard
        dashboardWidgets: [], // stores the actual widgets that are saved in the database, important for updating on different devices
        dashboardGridResponsive: {}, // stores the responsive state of the dashboard
        loadingDashboard: false
    }),

    actions: {
        setSidebarOpen(isSidebarOpen) {
            this.isSidebarOpen = isSidebarOpen
        },
        setWikiSidebarOpen(isWikiSidebarOpen) {
            this.wikiSidebarOpen = isWikiSidebarOpen
        },
        setCurrentModule(currentModule) {
            this.currentModule = currentModule
        },
        loadDashboard() {
            if (dashboard.editable) {
                this.loadingDashboard = true
                return $lara.get(useNuxtApp().$apiRoute('spa.dashboard.show')).then((response) => {
                    this.dashboardGridResponsive = response?.layouts || {}
                    this.dashboardWidgets = response?.widgets || []
                }).catch(() => {
                }).finally(() => {
                    this.loadingDashboard = false
                })
            }

            let xIndex = 0, yIndex = 0

            this.dashboardWidgets = dashboard.widgets.filter((widget) => {
                return !widget.ability || useAbility().can(widget.ability.action, widget.ability.subject)
            }).map((widget) => {
                const formattedWidget = {
                    ...widget,
                    id: uuid(),
                    settings: {},
                    dragAllowFrom: ".drag-area",
                    x: widget.x || xIndex,
                    y: widget.y || yIndex,
                    w: widget.width || 1,
                    h: widget.height || 1,
                    maxW: widget.maxWidth || 4,
                    maxH: widget.maxHeight || 4,
                    minW: widget.minWidth || 1,
                    minH: widget.minHeight || 1,
                    isDraggable: false,
                    isResizable: false
                }

                // BEMA specific, we expect 4 cols and no custom x/y values
                xIndex += formattedWidget.w
                if (xIndex >= 4) {
                    xIndex = 0
                    yIndex++
                }

                return formattedWidget
            })

            this.dashboardGrid = this.dashboardWidgets
        },
        saveDashboard() {
            if (dashboard.editable) {
                debouncedSave(this.dashboardGridResponsive, this.dashboardWidgets)
            }
        },
        addWidget(widget) {
            const newWidget = {
                ...widget,
                id: uuid(),
                settings: {},
                dragAllowFrom: ".drag-area",
                x: 0,
                y: 0,
                w: widget.width || 1,
                h: widget.height || 1,
                maxW: widget.maxWidth || 4,
                maxH: widget.maxHeight || 4,
                minW: widget.minWidth || 1,
                minH: widget.minHeight || 1,
                isDraggable: (widget.draggable ?? true),
                isResizable: (widget.resizable ?? true)
            }

            this.dashboardWidgets.push(newWidget)
        },
        removeWidget(item) {
            this.dashboardWidgets = this.dashboardWidgets.filter((widget) => widget.id !== item.id)
        }
    }
})
