export default {
    label: 'Beschaffen',

    recipient: "Warenempfänger",
    total_price: "Gesamtpreis",
    offer: "Angebot",
    searchOffer: "Angebot suchen",

    noHardOrSoftware: 'Nicht für Hard- oder Software',
    toBeSentBySelf: 'Bestellung muss selbst versendet werden',
    offerTendering: 'Mit Angebotseinholung',
    approvalProcess: 'Mit Genehmigungsprozess',

    createOrder: "Beschaffung anlegen",
    createOrderSuccess: 'Die Beschaffung wurde erfolgreich erstellt.',
    createOrderError: 'Die Beschaffung konnte nicht erstellt werden.',
}