import table from './table'

export default {
    provider: 'Anbieter',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    sp_certificate: 'SP Zertifikat',
    sp_private_key: 'SP Private Key',
    redirect: 'URL',
    tenant_id: 'Tenant ID',
    metadata_url: 'Verbundmetadatendokument',

    providerId: 'Login Methode {id}',
    redirectUrlCopied: 'Umleitungs-URI in die Zwischenablage kopiert',

    createProvider: 'Anbieter hinzufügen',
    deleteProvider: 'Anbieter löschen',
    deleteProviderInfo:
        'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    noOrganisationFound: 'Es konnte kein Lieferant gefunden werden.',
    providerAlreadyExists: 'Es existiert bereits eine Login Method zu diesem Anbieter.',
    providerNotDeletable: 'Dieser Anbieter kann nicht gelöscht werden.',
    deleteProviderSuccess: 'Der Anbieter wurde erfolgreich gelöscht.',
    redirect_url: 'Umleitungs-URI',
    sp_acs: 'Service Provider ACS-URL',
    sp_entityid: 'Service Provider Entity ID',

    providers: {
        saml2: 'SAML2',
        google: 'Google',
        azure: 'Microsoft'
    },

    table
}