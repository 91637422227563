export default {
    title: 'Login Methode erstellen',
    provider: 'Anbieter',
    client_id: 'Client ID (Anwendungs-ID)',
    client_secret: 'Client Secret',
    redirect: 'URL',
    tenant_id: 'Tenant ID',
    metadata_url: 'Verbundmetadaten URL',

    createProvider: 'Anbieter hinzufügen',
    deleteProvider: 'Anbieter löschen',

    noOrganisationFound: 'Es konnte kein Lieferant gefunden werden.',
    providerAlreadyExists: 'Es existiert bereits eine Login Method zu diesem Anbieter.',
    providerNotDeletable: 'Dieser Anbieter kann nicht gelöscht werden.',
    createProviderSuccess: 'Der Anbieter wurde erfolgreich erstellt.',
    deleteProviderSuccess: 'Der Anbieter wurde erfolgreich gelöscht.',
    providers: {
        google: 'Google',
        azure: 'Microsoft'
    }
}