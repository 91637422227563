export default {
    newest: 'Neueste',
    oldest: 'Letzte',
    noEntries: 'Keine Einträge gefunden',

    demo: {
        openAi: "Open Ai Demo"
    },

    admins: {
        title: "Admins",
        tableIndex: "Übersicht Admins"
    },

    organisations: {
        tableIndex: "Übersicht Organisationen"
    },

    myOrders: {
        title: "Meine Beschaffungen",
        subtitle: "Übersicht"
    },

    costCenterApprovals: {
        title: "Zu genehmigen",
        subtitle: "Durch Kostenstelle"
    },

    financeApprovals: {
        title: "Zu genehmigen",
        subtitle: "Durch Finanzabteilung"
    },

    dataCenterApprovals: {
        title: "Zu genehmigen",
        subtitle: "Durch Rechenzentrum"
    },

    techDepartmentApprovals: {
        title: "Zu genehmigen",
        subtitle: "Durch Technische Abteilung"
    },

    openTendering: {
        title: "Offene Anfragen",
        subtitle: "Für Ihren Lieferanten"
    }
}