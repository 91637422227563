import { default as indexQlAcxLimm2Meta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/company/index.vue?macro=true";
import { default as indexJeV1psD2kYMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/index.vue?macro=true";
import { default as indexqFu77OJcPvMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/social/[provider]/index.vue?macro=true";
import { default as _91id_93BoefmMnAjCMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/categories/[id].vue?macro=true";
import { default as indext18Q1N2ATUMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93B0zojt4PnLMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as indexQkgE5Z1yhlMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as baseDataXqwgIdLXQSMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData.vue?macro=true";
import { default as companyBaseDataW5ovkNnMz7Meta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/company/companyBaseData.vue?macro=true";
import { default as company8QkhtJyy40Meta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/company.vue?macro=true";
import { default as indexTsXno13URuMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/index.vue?macro=true";
import { default as orderBaseDatarcKwqAU1dAMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderBaseData.vue?macro=true";
import { default as orderDetailsDsjvxJqqiMMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderDetails.vue?macro=true";
import { default as orderHistoryoeZlEjLrBeMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderHistory.vue?macro=true";
import { default as orderOfferste5YMEUf3oMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderOffers.vue?macro=true";
import { default as _91id_93Fpqyy0y4BHMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id].vue?macro=true";
import { default as indexcjpkLDlhhNMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/index.vue?macro=true";
import { default as organisationBaseDataY0uqgNdHLTMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93VXUaGDzVCyMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/[id].vue?macro=true";
import { default as indextLS3BjtcHLMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/index.vue?macro=true";
import { default as organisationsN16y3RdxKtMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations.vue?macro=true";
import { default as dungeonRTZqEbgBNbMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputss7xGPfbuRdMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexBAaZwnvHlHMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index.vue?macro=true";
import { default as languagevEfVdKj5mjMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/language.vue?macro=true";
import { default as _91id_93LiDDvOX0HLMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexueHqYI3IuFMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93aLVmEcp0iEMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexqGwLQs5IIoMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as tenderBaseDataL2VdDWrbbvMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/[id]/tenderBaseData.vue?macro=true";
import { default as _91id_93M4eduurj1dMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/[id].vue?macro=true";
import { default as indexmMYP7YoRM8Meta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/index.vue?macro=true";
import { default as userActivitiesJ33jhs8IrUMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataY7ygeByrooMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationst4Blb25u2aMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_9326AAnMsuJYMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id].vue?macro=true";
import { default as index1kybkmzYWMMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/index.vue?macro=true";
import { default as usersErfI2lPKdbMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93LkLlIORxrKMeta } from "/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth-company",
    path: "/auth/company",
    meta: indexQlAcxLimm2Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/company/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexJeV1psD2kYMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-provider",
    path: "/auth/social/:provider()",
    meta: indexqFu77OJcPvMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/auth/social/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData",
    path: "/baseData",
    meta: baseDataXqwgIdLXQSMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData.vue").then(m => m.default || m),
    children: [
  {
    name: "baseData-categories-id",
    path: "categories/:id()",
    meta: _91id_93BoefmMnAjCMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "categories",
    meta: indext18Q1N2ATUMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "costCenters/:id()",
    meta: _91id_93B0zojt4PnLMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "costCenters",
    meta: indexQkgE5Z1yhlMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "company",
    path: "/company",
    meta: company8QkhtJyy40Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: "company-companyBaseData",
    path: "companyBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/company/companyBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93Fpqyy0y4BHMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-orderBaseData",
    path: "orderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderDetails",
    path: "orderDetails",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderDetails.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderHistory",
    path: "orderHistory",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderHistory.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderOffers",
    path: "orderOffers",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/[id]/orderOffers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexcjpkLDlhhNMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: organisationsN16y3RdxKtMeta?.name,
    path: "/organisations",
    meta: organisationsN16y3RdxKtMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93VXUaGDzVCyMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indextLS3BjtcHLMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-language",
    path: "/settings/language",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "/settings/loginProviders/:id()",
    meta: _91id_93LiDDvOX0HLMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "/settings/loginProviders",
    meta: indexueHqYI3IuFMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "/settings/mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93aLVmEcp0iEMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "/settings/mailTemplates",
    meta: indexqGwLQs5IIoMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: "tenders-id",
    path: "/tenders/:id()",
    meta: _91id_93M4eduurj1dMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "tenders-id-tenderBaseData",
    path: "tenderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/[id]/tenderBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tenders",
    path: "/tenders",
    meta: indexmMYP7YoRM8Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/tenders/index.vue").then(m => m.default || m)
  },
  {
    name: usersErfI2lPKdbMeta?.name,
    path: "/users",
    meta: usersErfI2lPKdbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_9326AAnMsuJYMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: index1kybkmzYWMMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93LkLlIORxrKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250403102820/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]