import info from './info'
import menu from './menu'
import table from './table'

export default {
    organisationId: 'Lieferant {id}',
    history: 'Historie',

    logo: 'Logo',
    name: 'Name',
    email: 'E-Mail',
    address: 'Anschrift',
    street: 'Straße',
    housenumber: 'Hausnummer',
    zipcode: 'PLZ',
    city: 'Ort',
    country: 'Land',
    membersCount: 'Mitglieder',
    organisationUsers: 'Ansprechpersonen',
    category: 'Abonnierte Kategorien',
    searchCategory: 'Kategorie suchen',
    categoryDescription: 'Über die Kategorien wird bestimmt, zu welchen Beschaffungen Sie als potentieller Lieferant benachrichtigt werden.',

    removeSupplier: 'Ansprechperson entfernen',
    removeSupplierInfo: 'Möchtest du diese Ansprechperson wirklich von diesem Lieferanten entfernen?',
    createUserSuccess: 'Der Nutzer wurde erfolgreich erstellt.',
    createUserError: 'Der Nutzer konnte nicht erstellt werden.',
    storeUserSuccess: 'Der Nutzer wurde erfolgreich angelegt.',
    storeUserError: 'Der Nutzer konnte nicht angelegt werden.',
    removeUserSuccess: 'Der Nutzer wurde erfolgreich entfernt.',
    removeUserError: 'Der Nutzer konnte nicht entfernt werden.',
    invitationLinkCopied: 'Der Einladungslink wurde in die Zwischenablage kopiert.',

    createUser: 'Ansprechperson hinzufügen',
    updateUser: 'Ansprechperson bearbeiten',

    createOrganisation: 'Neuen Lieferant "{name}" anlegen',
    organisationHasOffer: 'Für den Lieferant "{name}" wurde bereits ein Angebot hinterlegt.',
    searchForOrganisation: 'Geben Sie den Namen Ihres Lieferanten ein',
    noOrganisationFound: 'Kein Lieferant gefunden',
    createOrganisationSuccess: 'Der Lieferant wurde erfolgreich erstellt.',
    createOrganisationError: 'Der Lieferant konnte nicht erstellt werden.',

    deleteOrganisation: 'Lieferant löschen',
    deleteOrganisationText:
        'Möchtest du die Organisation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrganisationSuccess: 'Lieferant wurde erfolgreich gelöscht.',
    deleteOrganisationError: 'Der Lieferant konnte nicht gelöscht werden.',

    toggleState: 'aktivieren / deaktivieren',
    toggleStateSuccess: 'Status wurde erfolgreich geändert.',
    toggleStateError: 'Der Status konnte nicht geändert werden.',

    info,
    menu,
    table
}