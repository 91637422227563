export default {
    title: 'Neuen Lieferant anlegen',
    name: 'Name',
    email: 'E-Mail',
    zipcode: 'PLZ',
    city: 'Ort',
    street: 'Straße',
    housenumber: 'Hausnummer',
    country: 'Land',
    createOrganisation: 'Neuen Lieferant anlegen',
    createOrganisationSuccess: 'Der Lieferant wurde erfolgreich erstellt.',
    createOrganisationError: 'Der Lieferant konnte nicht erstellt werden.'
}