export default {
    title: 'Ergebnis',
    actionsExecuted: 'Es wurde kein Ereignis festgehalten|Es wurde ein Ereignis festgehalten|Es wurden {count} Ereignisse festgehalten',

    type_default_action: "Keine Aktion ausgeführt|Eine Aktion ausgeführt|{count} Aktionen ausgeführt",
    type_user_deleted: "Kein Nutzer gelöscht|Ein Nutzer gelöscht|{count} Nutzer gelöscht",
    type_user_not_deletable: "Der Nutzer konnte nicht gelöscht werden|Ein Nutzer konnte nicht gelöscht werden|{count} Nutzer konnten nicht gelöscht werden",
    type_cost_center_deleted: "Keine Kostenstelle gelöscht|Eine Kostenstelle gelöscht|{count} Kostenstellen gelöscht",
    type_cost_center_not_deletable: "Die Kostenstelle konnte nicht gelöscht werden|Eine Kostenstelle konnte nicht gelöscht werden|{count} Kostenstellen konnten nicht gelöscht werden",
    type_cost_center_activated: "Keine Kostenstelle aktiviert|Eine Kostenstelle aktiviert|{count} Kostenstellen aktiviert",
    type_cost_center_deactivated: "Keine Kostenstelle deaktiviert|Eine Kostenstelle deaktiviert|{count} Kostenstellen deaktiviert",
    type_cost_center_not_activatable: "Die Kostenstelle konnte nicht aktiviert werden|Eine Kostenstelle konnte nicht aktiviert werden|{count} Kostenstellen konnten nicht aktiviert werden",
    type_cost_center_not_deactivatable: "Die Kostenstelle konnte nicht deaktiviert werden|Eine Kostenstelle konnte nicht deaktiviert werden|{count} Kostenstellen konnten nicht deaktiviert werden",
    type_order_deleted: "Keine Beschaffung gelöscht|Eine Beschaffung gelöscht|{count} Beschaffungen gelöscht",
    type_order_not_deletable: "Die Beschaffung konnte nicht gelöscht werden|Eine Beschaffung konnte nicht gelöscht werden|{count} Beschaffungen konnten nicht gelöscht werden",
}
