export const dashboard: Dashboard = {
    rowHeight: 200,
    editable: false,
    horizontalShift: true,
    columns: {
        'xxs': 1,
        'xs': 2,
        'sm': 3,
        'md': 4,
        'lg': 4,
    },
    widgets: [{
        module: 'primary',
        color: 'primary',
        name: 'orders.orderStatus',
        height: 2,
        ability: {
            action: 'create',
            subject: 'orders'
        },
        props: {
            title: 'dashboard.widgets.myOrders.title',
            subtitle: 'dashboard.widgets.myOrders.subtitle',
            filter: [{
                name: "qf_myOrders",
                column: "customFilterMyOrders",
                value: true,
                operator: "="
            }]
        }
    }, {
        module: 'cyan',
        name: 'orders.orderStatus',
        height: 2,
        ability: {
            action: 'manager',
            subject: 'costCenters'
        },
        props: {
            title: 'dashboard.widgets.costCenterApprovals.title',
            subtitle: 'dashboard.widgets.costCenterApprovals.subtitle',
            filter: [{
                name: "customFilterCostCenterApproval",
                column: "customFilterCostCenterApproval",
                value: true,
                operator: "="
            }]
        }
    }, {
        module: 'teal',
        name: 'orders.orderStatus',
        height: 2,
        ability: {
            action: 'represent',
            subject: 'finance'
        },
        props: {
            title: 'dashboard.widgets.financeApprovals.title',
            subtitle: 'dashboard.widgets.financeApprovals.subtitle',
            filter: [{
                name: "customFilterFinanceApproval",
                column: "customFilterFinanceApproval",
                value: true,
                operator: "="
            }]
        }
    }, {
        module: 'emerald',
        name: 'orders.orderStatus',
        height: 2,
        ability: {
            action: 'represent',
            subject: 'dataCenter'
        },
        props: {
            title: 'dashboard.widgets.dataCenterApprovals.title',
            subtitle: 'dashboard.widgets.dataCenterApprovals.subtitle',
            filter: [{
                name: "customFilterDataCenterApproval",
                column: "customFilterDataCenterApproval",
                value: true,
                operator: "="
            }]
        }
    }, {
        module: 'emerald',
        name: 'orders.orderStatus',
        height: 2,
        ability: {
            action: 'represent',
            subject: 'techDepartment'
        },
        props: {
            title: 'dashboard.widgets.techDepartmentApprovals.title',
            subtitle: 'dashboard.widgets.techDepartmentApprovals.subtitle',
            filter: [{
                name: "customFilterTechDepartmentApproval",
                column: "customFilterTechDepartmentApproval",
                value: true,
                operator: "="
            }]
        }
    }, {
        module: 'indigo',
        name: 'orders.tenderStatus',
        height: 2,
        ability: {
            action: 'represent',
            subject: 'organisations'
        },
        props: {
            title: 'dashboard.widgets.openTendering.title',
            subtitle: 'dashboard.widgets.openTendering.subtitle',
            filter: [{
                name: "customFilterActiveRequests",
                column: "customFilterActiveRequests",
                value: true,
                operator: "="
            }]
        }
    }]
}
