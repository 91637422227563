export default {
    title: 'Beschaffungen',
    order_number: 'BA.-Nummer',
    tender_number: 'A.-Nr.',
    type: 'Art',
    price: 'Betrag',
    price_net: 'Betrag (netto)',
    user_id: 'Mitarbeiter',
    category_id: 'Kategorie',
    cost_center_id: 'Kostenstelle',
    created_at: 'Datum',
    status: 'Status',
    date: 'Datum',
    year: 'Jahr',
    reason: 'Grund der Angebotswahl',
    category: 'Kategorie',
    open: 'Entwurf',
    positions: 'Bestellung',
    qf_toApprove: 'Zu Genehmigen',
    qf_myOrders: 'Meine Beschaffungen',
    qf_closed: 'Abgeschlossen'
}